<sei-dashboard-tile [title]="account.accountName"
                    panelClass="panel-default">
    <div panel-heading-extra-content>
        <sei-button buttonText="Modify Investments"
                    buttonClass="sei-btn-link-padding-none"
                    [buttonDisabled]="!verifyExistingAccountCardCompleted()"
                    (buttonClicked)="onModifyInvestmentsClicked()"></sei-button>
        <span class="card-pipe">|</span>
        <sei-button buttonRightFontAwesomeIcon="fa-undo"
                    buttonClass="btn-link"
                    (buttonClicked)="accountResetClicked()"></sei-button>
        <span class="card-pipe">|</span>
        <sei-button buttonRightFontAwesomeIcon="fa-trash-alt"
                    buttonClass="btn-link"
                    (buttonClicked)="removeSelectedExistingAccount()"></sei-button>
    </div>
    <div panel-content>
        <div class="row">
            <div class="col-xs-6 form-group"
                 [ngClass]="{'has-error': !isAmountValid}">
                <label class="card-label">{{ calculateAmountDifference() >= 0 ? 'New Funds' : 'Remove Funds' }}</label>
                <p>{{ {value: amountDifferenceAbsoluteValue(), actualCurrency: 'USD' } | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}</p>
            </div>
            <div class="col-xs-6">
                <label class="card-label">Investment Program</label>
                <sei-dropdown id="investmentProgramDropdown"
                              [items]="investmentProgramDropdownItems"
                              [disabled]="investmentProgramDropdownDisabled"
                              [ngModel]="selectedInvestmentProgramSet"
                              (ngModelChange)="onInvestmentProgramChange($event)"></sei-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-6 form-group">
                <label class="card-label">Amount</label>
                <p>{{ {value: account.actualCurrentMarketValue, actualCurrency: 'USD' } | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}</p>
            </div>
            <div class="col-xs-6 form-group">
                <label class="card-label"
                       for="accountTypeDropdown">Account Type</label>
                <sei-dropdown id="accountTypeDropdown"
                              [disabled]="accountTypesSet.size === 1"
                              [items]="accountTypesSet"
                              [ngModel]="selectedAccountTypeSet"
                              (ngModelChange)="onAccountTypeDropdownChange($event)"></sei-dropdown>
            </div>
        </div>
        <div class="row"
             *ngFor="let client of selectedClientList; let i=index">
            <div class="col-xs-12 form-group">
                <sei-car-search-owner-form [index]="i"
                                           [client]="client"
                                           [entitySearchEnabled]="false"
                                           [clientList]="proposalClientList"
                                           [selectedClientList]="selectedClientList"
                                           [isHeaderLabel]="true"
                                           [accountTypeId]="accountTypeId"
                                           [existingAccountContactTypeId]="existingAccountTypeId"
                                           [hideDeleteIcon]="true"></sei-car-search-owner-form>
            </div>
        </div>
    </div>
</sei-dashboard-tile>
<sei-car-warning-modal #investmentProgramModal
                       [message]="modalMessage"
                       [showModal]="showModal"
                       (continueClicked)="onModalContinue()"
                       (cancelClicked)="onModalCancel()"></sei-car-warning-modal>
<sei-car-warning-modal #resetAccountModal
                       [message]="accountResetModalMessage"
                       [modalHeader]="account.accountName"
                       [modalTitle]="'Account Reset'"
                       [showModal]="showResetModal"
                       (continueClicked)="onResetModalContinue()"
                       (cancelClicked)="onResetModalCancel()"></sei-car-warning-modal>
