/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { WarningModalConstants } from '@CarModels/constants';
import { Account, AccountOwner, AccountType, Client, CommandText, ContactTypes, DelegationService, EntityType, OwnerRole, OwnerRoleDescription, ProcessingRule, Proposal, ProposalAccountService, ProposalService, Scenario, TypeOfOwner, TypeOfOwnerId, WipAccountStatus, WipCheckList, WipChecklistLabels } from '@sei/advisor-client-review-proposal-ux';
import { DisplayNullOptions, DropdownItem, EditAmountOptions } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { OrderedSet } from 'immutable';
import * as _ from 'lodash';
import { AccountTypes } from 'projects/advisor-client-review-proposal-ux/src/lib/model/account-type-options';
import { AccountFactory } from 'projects/advisor-client-review-proposal-ux/src/lib/model/factory/account-factory';
import { ClientFactory } from 'projects/advisor-client-review-proposal-ux/src/lib/model/factory/client-factory';
import { InvestmentProgram } from 'projects/advisor-client-review-proposal-ux/src/lib/model/investment-program';
import { ContactTypeUtilityService } from 'projects/advisor-client-review-proposal-ux/src/lib/service/contact-type-utility.service';

@Component({
    selector: 'sei-car-proposed-accounts-card',
    templateUrl: './proposed-accounts-card.component.html',
    styleUrls: ['./proposed-accounts-card.component.scss']
})
export class ProposedAccountsCardComponent extends ParentComponentSubscriptionManager implements OnInit {
    @Input()
    public account: Account;

    @Input()
    set index(value: number) {
        this._index = value;
        this.setLabelText();
    }

    get index(): number {
        return this._index;
    }

    @Input()
    public proposal: Proposal;

    @Input()
    set accountTypes(value: AccountType[]) {
        this._accountTypes = value;
        this.setAccountTypeDropdownItems();
        if (this.accountTypeId) {
            const accountType: AccountType = this.accountTypes.find((type: AccountType) => type.accountTypeId === this.accountTypeId);
            this.investmentPrograms = accountType.investmentPrograms;
            this.allowJointOwners = accountType.allowJointOwners;
            this.nonTaxableStatus = accountType.nonTaxableStatus;
            this.typeOfOwnersDropdownItems = this.mapTypeOfOwnersToDropdownItems(accountType.typeOfOwners);
            if (this.account?.typeOfOwnerId) {
                const typeOfOwnerDropdownItem: DropdownItem = this.typeOfOwnersDropdownItems.find((dropdownItem: DropdownItem) =>
                    dropdownItem.id === this.account?.typeOfOwnerId);
                this.selectedtypeOfOwnersDropdownItems = OrderedSet([typeOfOwnerDropdownItem]);
                this.typeOfOwnerId = this.account.typeOfOwnerId;
            }
            this.mapInvestmentProgramDropdownItems();
        }
    }

    get accountTypes(): AccountType[] {
        return this._accountTypes;
    }

    @Input()
    public removeQualifiedPlans: boolean;

    @Input()
    public remove403b: boolean;

    @Output()
    public onAccountDelete: EventEmitter<number> = new EventEmitter();


    public amountOptions: EditAmountOptions;
    public processingRules: ProcessingRule;
    public isAmountValid: boolean;
    public amountErrors: string[];
    public clientList: Client[];
    public proposalClients: Client[];
    public enableAddOwner: boolean = false;
    public accountTypesSet: OrderedSet<DropdownItem> = OrderedSet([]);
    public selectedAccountTypeSet: OrderedSet<DropdownItem> = OrderedSet([]);
    public isInvestmentSelectionEnabled = false;
    public labelText: string;
    public accountTypeId: number;
    public investmentProgramDropdownItems: OrderedSet<DropdownItem> = OrderedSet([]);
    public selectedInvestmentProgramSet: OrderedSet<DropdownItem> = OrderedSet([]);
    public selectedInvestmentProgramType: string;
    public showModal: boolean = false;
    public selectedAccountTypeBackupSet: OrderedSet<DropdownItem> = OrderedSet([]);
    public selectedInvestmentProgramBackupSet: OrderedSet<DropdownItem> = OrderedSet([]);
    public modalMessage: string = '';
    public allowJointOwners: boolean = false;
    public nonTaxableStatus: boolean = true;
    public typeOfOwnersDropdownItems: OrderedSet<DropdownItem> = OrderedSet([]);
    public selectedtypeOfOwnersDropdownItems: OrderedSet<DropdownItem> = OrderedSet([]);
    public typeOfOwnerId: TypeOfOwnerId;

    private proposalId: number;
    private scenarioId: string;
    private _accountTypes: AccountType[];
    private _index: number;
    private currentModalState: string;
    private investmentPrograms: InvestmentProgram[] = [];

    constructor(private delegationService: DelegationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private proposalService: ProposalService,
        private contactTypeUtilityService: ContactTypeUtilityService,
        private proposalAccountService: ProposalAccountService) {
        super('ProposedAccountsCardComponent');
    }

    ngOnInit(): void {
        this.setAmountOptions();
        this.setClientList();
        this.enableAddOwner = this.proposalService.validateAllOwners(this.clientList);
        this.proposalId = this.proposal.id;
        this.accountTypeId = this.account?.type?.id || this.account?.type?.accountTypeId;
        if (this.accountTypeId) {
            const accountType: AccountType = this.accountTypes.find((type: AccountType) => type.accountTypeId === this.accountTypeId);
            this.investmentPrograms = accountType.investmentPrograms;
            this.allowJointOwners = accountType.allowJointOwners;
            this.nonTaxableStatus = accountType.nonTaxableStatus;
            this.typeOfOwnersDropdownItems = this.mapTypeOfOwnersToDropdownItems(accountType.typeOfOwners);
            if (this.account?.typeOfOwnerId) {
                const typeOfOwnerDropdownItem: DropdownItem = this.typeOfOwnersDropdownItems.find((dropdownItem: DropdownItem) =>
                    dropdownItem.id === this.account?.typeOfOwnerId);
                this.typeOfOwnerId = this.account?.typeOfOwnerId;
                this.selectedtypeOfOwnersDropdownItems = OrderedSet([typeOfOwnerDropdownItem]);
            }
            this.mapInvestmentProgramDropdownItems();
        }
        if (this?.account?.investmentProgramId) {
            const investmentProgramDropdownItem: DropdownItem = this.investmentProgramDropdownItems.find((dropdownItem: DropdownItem) =>
                dropdownItem.id === this.account.investmentProgramId);
            this.selectedInvestmentProgramSet = OrderedSet([investmentProgramDropdownItem]);
            this.account.investmentProgramType = investmentProgramDropdownItem.name;
        }
        this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
            this.scenarioId = params.get('scenarioId');
        });
        this.setAccountTypeDropdownItems();
        this.subscriptions.push(this.delegationService.refresh().subscribe((commandText: CommandText) => {
            if (commandText === CommandText.RefreshClientList) {
                this.setClientList();
            }
        }));
    }

    public onAccountBalanceChange(amount: number): void {
        this.account.balance = amount;
        this.validateProposal();
    }

    public onAccountBalanceError(errors: string[]) {
        this.isAmountValid = errors.length < 1;
        this.amountErrors = errors;
    }

    public onAddOwner(): void {
        const client: AccountOwner = new ClientFactory().createAccountOwner(this.proposal?.clients?.length + 1);
        client.isNewClient = true;
        client.contactId = client.id;
        client.entityType = EntityType.Prospect;
        this.proposal.clients.push(client);
        this.account.owners.push(client);
        this.enableAddOwner = this.proposalService.validateAllOwners(this.clientList);
        this.validateProposal();
    }

    public onClientChange(accountOwner: AccountOwner, index: number): void {
        accountOwner.ownerRole = { id: OwnerRole.Primary, description: OwnerRoleDescription.Primary };
        this.account.owners[index] = accountOwner;
        this.setClientList();
        this.validateProposal();
        this.enableAddOwner = this.proposalService.validateAllOwners(this.clientList);
        this.delegationService.publish(CommandText.RefreshClientList);
    }

    public onClientDeleted(index: number): void {
        this.account.owners.splice(index, 1);
        this.validateProposal();
        this.enableAddOwner = this.proposalService.validateAllOwners(this.clientList);
        if (!this.account.owners || this.account.owners.length === 0) {
            this.onAddOwner();
        }
        this.delegationService.publish(CommandText.RefreshClientList);
    }

    public navigateToInvestmentSummary() {
        this.proposal.scenarios.forEach((scenario: Scenario) => {
            scenario.accounts.forEach((account: Account, index: number) => {
                if (account.name === undefined || account.name === '') {
                    account.name = `Proposed Account ${index + 1}`;
                }
            });
        });

        this.router.navigate([`Proposal/WIP/${this.proposalId}/Scenarios/${this.scenarioId}/Accounts/${this.account.id}`]);
    }

    public accountDeleteClicked(): void {
        this.onAccountDelete.emit(this.account?.id);
    }

    public onAccountTypeDropdownChange(accountTypeDropdownSet: OrderedSet<DropdownItem>): void {
        this.selectedAccountTypeBackupSet = _.cloneDeep(this.selectedAccountTypeSet);
        this.selectedInvestmentProgramBackupSet = _.cloneDeep(this.selectedInvestmentProgramSet);
        this.selectedAccountTypeSet = accountTypeDropdownSet;
        if (!this.isInvestmentProgramValid()) {
            this.currentModalState = WarningModalConstants.MODAL_STATE_ACCOUNT_TYPE;
            this.showWarningModal();
        } else {
            this.accountTypeChange();
        }
    }

    public onInvestmentProgramChange(selectedInvestmentPrograms: OrderedSet<DropdownItem>) {
        this.selectedInvestmentProgramBackupSet = _.cloneDeep(this.selectedInvestmentProgramSet);
        if (!this.currentModalState && this.selectedInvestmentProgramSet?.first()) {
            this.selectedInvestmentProgramSet = _.cloneDeep(selectedInvestmentPrograms);
            this.currentModalState = WarningModalConstants.MODAL_STATE_INVESTMENT_PROGRAM;
            if (this.selectedInvestmentProgramSet?.first()) {
                this.showWarningModal();
            }
        } else {
            this.selectedInvestmentProgramSet = _.cloneDeep(selectedInvestmentPrograms);
            this.investmentProgramChange();
        }
    }

    public onModalContinue(): void {
        this.showModal = false;
        switch (this.currentModalState) {
            case WarningModalConstants.MODAL_STATE_ACCOUNT_TYPE:
                this.accountTypeChange();
                break;
            case WarningModalConstants.MODAL_STATE_INVESTMENT_PROGRAM:
                this.investmentProgramChange();
                break;
        }
        this.currentModalState = undefined;
    }

    public onModalCancel(): void {
        this.showModal = false;
        switch (this.currentModalState) {
            case WarningModalConstants.MODAL_STATE_ACCOUNT_TYPE:
                this.selectedAccountTypeSet = _.cloneDeep(this.selectedAccountTypeBackupSet);
                this.selectedAccountTypeBackupSet = undefined;
                this.selectedInvestmentProgramSet = _.cloneDeep(this.selectedInvestmentProgramBackupSet);
                this.selectedInvestmentProgramBackupSet = undefined;
                break;
            case WarningModalConstants.MODAL_STATE_INVESTMENT_PROGRAM:
                this.selectedInvestmentProgramSet = _.cloneDeep(this.selectedInvestmentProgramBackupSet);
                this.selectedInvestmentProgramBackupSet = undefined;
                break;
        }
        this.currentModalState = undefined;
    }

    public onTypeOfOwnerChange(selectedTypeOfOwner: OrderedSet<DropdownItem>) {
        const previousTypeOfOwnerId: number = _.cloneDeep(this.account.typeOfOwnerId);
        this.selectedtypeOfOwnersDropdownItems = selectedTypeOfOwner;
        this.account.typeOfOwnerId = Number(selectedTypeOfOwner?.first()?.id);
        this.typeOfOwnerId = this.account.typeOfOwnerId;
        if (this.typeOfOwnerId) {
            const contactTypeChanged: boolean = this.proposalAccountService.getContactTypeFromTypeOfOwner(this.typeOfOwnerId) !==
                this.proposalAccountService.getContactTypeFromTypeOfOwner(previousTypeOfOwnerId);
            if (contactTypeChanged) {
                this.account.owners = [];
                this.onAddOwner();
                this.setClientList();
                this.enableAddOwner = false;
            }
        }
    }

    public isTypeOfOwnerRequired(): boolean {
        return this.typeOfOwnersDropdownItems?.size > 0;
    }

    private accountTypeChange(): void {
        const accountTypeDropdownItem: DropdownItem = this.selectedAccountTypeSet.first();
        const accountTypeItem: AccountType =
            this.accountTypes.find((accountType: AccountType) => accountType.accountTypeId === accountTypeDropdownItem.id);
        accountTypeItem.id = accountTypeItem.accountTypeId;
        accountTypeItem.description = accountTypeItem.accountTypeDescription;
        const hasAccountType: boolean = this.account.type && this.account.type.id > 0;
        const isContactTypeEqual: boolean = hasAccountType &&
            this.contactTypeUtilityService.getContactTypeFromAccountTypeId(this.account.type.id).accountContactTypes[0] !==
            this.contactTypeUtilityService.getContactTypeFromAccountTypeId(accountTypeItem.id).accountContactTypes[0];
        if (isContactTypeEqual) {
            this.account.owners = [];
            this.onAddOwner();
        }
        const typeOfOwnerDifference: boolean = !this.account?.type?.typeOfOwners && !!accountTypeItem.typeOfOwners;
        const isTypeOfOwnerValid: boolean = !this.account?.typeOfOwnerId ||
            (!!accountTypeItem.typeOfOwners && accountTypeItem.typeOfOwners.some((typeOfOwner: TypeOfOwner) =>
                typeOfOwner?.typeOfOwnerId === this.account?.typeOfOwnerId));
        const hasJointOwners: boolean = this.account?.owners?.length > 1;
        if (hasJointOwners && !accountTypeItem?.allowJointOwners) {
            this.account.owners = [this.account.owners[0]];
        }
        this.resetAccountRtqIfNecessary(accountTypeItem);
        this.account.type = _.cloneDeep(accountTypeItem);
        this.account.sweepModelElection = _.cloneDeep(accountTypeItem.sweepModelElection);
        this.accountTypeId = this.account?.type?.id;
        this.investmentPrograms = accountTypeItem.investmentPrograms;
        this.mapInvestmentProgramDropdownItems();
        this.account.isAccountTypeChanged = true;
        this.allowJointOwners = accountTypeItem.allowJointOwners;
        this.nonTaxableStatus = accountTypeItem.nonTaxableStatus;
        this.typeOfOwnersDropdownItems = this.mapTypeOfOwnersToDropdownItems(accountTypeItem.typeOfOwners);
        if (typeOfOwnerDifference || !isTypeOfOwnerValid) {
            this.account.typeOfOwnerId = undefined;
            this.typeOfOwnerId = undefined;
            this.selectedtypeOfOwnersDropdownItems = OrderedSet([]);
            this.account.owners = [];
            this.onAddOwner();
        }
        this.setClientList();
        this.enableAddOwner = this.proposalService.validateAllOwners(this.clientList);
        if (!this.isInvestmentProgramValid()) {
            // If investment program is not valid during account type change we also need to treat this as an investment program change
            this.investmentProgramChange();
            return;
        }
        this.validateProposal();
    }

    private resetAccountRtqIfNecessary(newlySelectedAccountType: AccountType): void {
        if (newlySelectedAccountType?.qualifiedPlan !== this.account?.type?.qualifiedPlan) {
            this.account.riskTolerance = new AccountFactory().createRiskTolerance();
            this.resetWipChecklistForAccount();
            this.proposalService.changedProposal(this.proposal);
        }
    }

    private resetWipChecklistForAccount(): void {
        const proposalScenarioWip: WipCheckList =
            this.proposal?.wipCheckList?.find((wip) => wip?.sectionType === WipChecklistLabels.ProposalScenario);
        if (proposalScenarioWip) {
            const reconstructedRoute: string = `Scenarios/${this.proposal?.scenarios[0]?.id}/Accounts/${this.account?.id}`;
            const wipChecklistForAccount: WipCheckList = proposalScenarioWip.subCheckList?.find((wip) => wip?.route === reconstructedRoute);
            if (wipChecklistForAccount) {
                wipChecklistForAccount.mdcSnapShot.lastTabVisited = WipAccountStatus.New;
                wipChecklistForAccount.mdcSnapShot.completed = WipAccountStatus.Incomplete;
            }
        }
    }

    private investmentProgramChange(): void {
        const investmentProgramValid: boolean = this.isInvestmentProgramValid();
        this.account.investmentProgramId = investmentProgramValid ?
            Number(this.selectedInvestmentProgramSet?.first()?.id) : undefined;
        this.account.investmentProgramType = investmentProgramValid ?
            this.selectedInvestmentProgramSet?.first()?.name : undefined;
        if (this.currentModalState) {
            this.account.strategies = [];
            this.account.portfolios = [];
        }

        const proposalScenarioWIPChecklist: WipCheckList =
            this?.proposal?.wipCheckList?.find((wipChecklist: WipCheckList) =>
                wipChecklist.sectionType === WipChecklistLabels.ProposalScenario);
        const accountWipChecklist =
            proposalScenarioWIPChecklist?.subCheckList?.find((wipChecklist: WipCheckList) =>
                Number(wipChecklist.mdcSnapShot.id) === this.account.id);

        if (accountWipChecklist && (accountWipChecklist.mdcSnapShot?.completed === WipAccountStatus.Complete)) {
            accountWipChecklist.mdcSnapShot.completed = WipAccountStatus.Incomplete;
        }

        this.validateProposal();
    }

    private isInvestmentProgramValid(): boolean {
        const selectedAccountTypeDropdownItem: DropdownItem = this.selectedAccountTypeSet?.first();
        const selectedAccountType: AccountType = this.accountTypes.find((accountType: AccountType) =>
            accountType.accountTypeId === selectedAccountTypeDropdownItem?.id);
        const selectedInvestProgramId: number = Number(this.selectedInvestmentProgramSet?.first()?.id);
        const isValid = !selectedInvestProgramId ||
            selectedAccountType.investmentPrograms.some((investmentProgram: InvestmentProgram) =>
                investmentProgram.investmentProgramId === selectedInvestProgramId);
        return isValid;
    }

    private setClientList(): void {
        this.clientList = this.account?.owners;
        this.proposalClients = this.proposal?.clients.filter((client: Client) => this.proposalService.validateOwner(client));
        this.proposalClients.forEach((client: Client) => {
            client.contactType.id === ContactTypes.Individual ?
                client.labelName = client.lastName +
                (client?.entitySuffix?.suffixName ? ` ${client.entitySuffix.suffixName}` : '') + ', ' +
                client.firstName :
                client.labelName = client.lastName;
        });
        if (!this.clientList || this.clientList.length === 0) {
            this.onAddOwner();
        }
    }

    private setAmountOptions(): void {
        this.amountOptions = {
            baseCurrency: 'USD',
            roundFractionalAmount: false,
            displayCurrencySymbol: true,
            minValueAllowed: '0',
            nullDisplay: DisplayNullOptions.ZERO
        };
    }

    private validateProposal(): void {
        const proposalValid: boolean = this.proposalService.validateProposal(this.proposal, Number(this.scenarioId));
        proposalValid ?
            this.delegationService.publish(CommandText.EnableSaveAndExitButton) :
            this.delegationService.publish(CommandText.DisableSaveAndExitButton);
        this.isInvestmentSelectionEnabled = proposalValid;
    }

    enableInvestmentSelection(): boolean {
        return this.proposalService.validateAccount(this.account);
    }

    private setAccountTypeDropdownItems(): void {
        this.accountTypesSet = OrderedSet([]);

        const isAccount403bType: boolean = this.account.type && this.account.type.description === AccountTypes._403BName.valueOf();
        const isAccountQualifiedPlan: boolean = this.account.type && this.account.type.qualifiedPlan;

        let filteredAccountTypes: AccountType[] = this.accountTypes;

        if (isAccount403bType && !isAccountQualifiedPlan) {
            filteredAccountTypes = this.filterBasedOnQualifiedPlans(filteredAccountTypes);
        } else if (!isAccount403bType && isAccountQualifiedPlan) {
            filteredAccountTypes = this.filterBasedOn403bType(filteredAccountTypes);
        } else {
            filteredAccountTypes = this.filterBasedOn403bType(filteredAccountTypes);
            filteredAccountTypes = this.filterBasedOnQualifiedPlans(filteredAccountTypes);
        }

        // Assign the filtered list to the dropdown
        if (filteredAccountTypes && filteredAccountTypes.length > 0) {
            filteredAccountTypes.forEach((accountType: AccountType) => {
                const dropdownItem: DropdownItem = new DropdownItem(accountType.accountTypeId, accountType.accountTypeDescription);
                this.accountTypesSet = OrderedSet([...this.accountTypesSet.toArray(), dropdownItem]);
            });
        }

        if (this.account.type) {
            const selectedDropdownItem: DropdownItem =
                this.accountTypesSet.find((accountTypeDropdownItem: DropdownItem) => accountTypeDropdownItem.id === this.account.type.id);
            this.selectedAccountTypeSet = selectedDropdownItem ? OrderedSet([selectedDropdownItem]) : undefined;
            const accountType: AccountType = this.accountTypes.find((type: AccountType) => type.accountTypeId === this.accountTypeId);
            this.investmentPrograms = accountType?.investmentPrograms;
            this.mapInvestmentProgramDropdownItems();
        }
    }

    private filterBasedOn403bType(filteredAccountTypes: AccountType[]) {
        if (this.remove403b) {
            filteredAccountTypes = filteredAccountTypes
                .filter((account: AccountType) => account.accountTypeDescription !== AccountTypes._403BName.valueOf());
        }
        return filteredAccountTypes;
    }

    private filterBasedOnQualifiedPlans(filteredAccountTypes: AccountType[]) {
        if (this.removeQualifiedPlans) {
            filteredAccountTypes = filteredAccountTypes.filter((account: AccountType) => !account.qualifiedPlan);
        }
        return filteredAccountTypes;
    }

    public checkAccountInProgress(): boolean {
        const proposalScenarioWIPChecklist: WipCheckList = this?.proposal?.wipCheckList
            ?.find((wipChecklist: WipCheckList) => wipChecklist.sectionType === WipChecklistLabels.ProposalScenario);
        const accountWipChecklist = proposalScenarioWIPChecklist?.subCheckList
            ?.find((wipChecklist: WipCheckList) => Number(wipChecklist.mdcSnapShot.id) === this.account.id);
        if (accountWipChecklist &&
            (accountWipChecklist.mdcSnapShot?.completed === WipAccountStatus.Complete)) {
            return true;
        } else {
            return false;
        }
    }

    private setLabelText(): void {
        this.labelText = `Proposed Account ${this.index + 1}`;
        if (this.account.name) {
            this.labelText += ` - ${this.account.name}`;
        }
    }

    private mapInvestmentProgramDropdownItems() {
        this.investmentProgramDropdownItems = OrderedSet([]);
        if (this.investmentPrograms) {
            this.investmentPrograms.sort((a: InvestmentProgram, b: InvestmentProgram) => a.sortOrder - b.sortOrder);
            this.investmentPrograms.forEach((investmentProgram: InvestmentProgram) => {
                const dropdownItem: DropdownItem =
                    new DropdownItem(investmentProgram.investmentProgramId, investmentProgram.investmentProgramDescription);
                this.investmentProgramDropdownItems = OrderedSet([...this.investmentProgramDropdownItems.toArray(), dropdownItem]);
            });
        }
    }

    private showWarningModal(): void {
        if ((this.account.strategies && this.account.strategies.length > 0) ||
            (this.account.portfolios && this.account.portfolios.length > 0)) {
            switch (this.currentModalState) {
                case WarningModalConstants.MODAL_STATE_ACCOUNT_TYPE:
                    this.modalMessage =
                        WarningModalConstants.MODAL_MESSAGE_ACCOUNT_TYPE;
                    break;
                case WarningModalConstants.MODAL_STATE_INVESTMENT_PROGRAM:
                    this.modalMessage =
                        WarningModalConstants.MODAL_MESSAGE_INVESTMENT_PROGRAM;
                    break;
            }
            this.showModal = true;
        } else {
            this.showModal = false;
            switch (this.currentModalState) {
                case WarningModalConstants.MODAL_STATE_ACCOUNT_TYPE:
                    this.accountTypeChange();
                    break;
                case WarningModalConstants.MODAL_STATE_INVESTMENT_PROGRAM:
                    this.investmentProgramChange();
                    break;
            }
        }
    }

    private mapTypeOfOwnersToDropdownItems(typeOfOwners: TypeOfOwner[]): OrderedSet<DropdownItem> {
        let typeOfOwnersDropdownItems: OrderedSet<DropdownItem> = OrderedSet([]);
        if (typeOfOwners && typeOfOwners.length > 0) {
            typeOfOwners = typeOfOwners.sort((a: TypeOfOwner, b: TypeOfOwner) => a.sortOrder - b.sortOrder);
            typeOfOwners.forEach((typeOfOwner: TypeOfOwner) => {
                const dropdownItem: DropdownItem = new DropdownItem(typeOfOwner.typeOfOwnerId, typeOfOwner.typeOfOwnerDescription);
                typeOfOwnersDropdownItems = OrderedSet([...typeOfOwnersDropdownItems.toArray(), dropdownItem]);
            });
        }
        return typeOfOwnersDropdownItems;
    }
}
