/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { AccountType } from '@CarInterfaces/account';
import { UserProfileRepository } from '@CarRepos/user-profile-repository';
import { AccountTypesService } from '@CarServices/account/account-types.service';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { SpinnerService } from '@CarServices/system/spinner.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Account, AccountOwner, Client, CommandText, DelegationService, GenericErrorService, Proposal, ProposalAccountService, ProposalService, Scenario, UserLevelEntitlement, UserProfile, UserProfileService } from '@sei/advisor-client-review-proposal-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Advisor, AdvisorRole, FirmInfoSlice, IASCommonAppStore } from '@sei/ias-applications-lib-ux';
import * as _ from 'lodash';
import { take } from 'rxjs';

const AllowedFlag: string = 'Yes';
@Component({
    selector: 'sei-car-proposed-accounts-form',
    templateUrl: './proposed-accounts-form.component.html',
    styleUrls: ['./proposed-accounts-form.component.scss']
})
export class ProposedAccountsFormComponent extends ParentComponentSubscriptionManager implements OnInit {
    @Input()
    public scenario: Scenario;

    @Input()
    public proposal: Proposal;

    @Input()
    public onlyShowExisting?: boolean = false;

    public accountTypes: AccountType[];
    private platformFeeBasisPoint: number = 0;
    public enableAddAccount: boolean = true;
    public remove403b: boolean = true;
    public removeQualifiedPlans: boolean = true;
    private userProfile: UserProfile;

    constructor(private delegationService: DelegationService,
        private proposalService: ProposalService,
        private router: Router,
        private genericErrorService: GenericErrorService,
        private spinnerService: SpinnerService,
        private accountTypesService: AccountTypesService,
        private appStore: Store<IASCommonAppStore>,
        private userProfileRepository: UserProfileRepository,
        private userProfileService: UserProfileService,
        private proposalAccountService: ProposalAccountService,
        private featureFlagService: FeatureFlagService) {
        super('ProposedAccountsFormComponent');
    }

    ngOnInit(): void {
        !this.proposalService.proposalBackup ? this.proposalService.proposalBackup = _.cloneDeep(this.proposal) : undefined;
        this.subscriptions.push(this.delegationService.refresh().subscribe((command: string) => {
            switch (command) {
                case CommandText.SaveEditProposal:
                    if (!this.featureFlagService.checkExistingAccountsSectionEnabled()) {
                        this.proposalService.fixProposalForSave(this.proposal);
                        this.spinnerService.start();
                        this.subscriptions.push(
                            this.proposalService.updateProposalScenario(this.proposal, this.scenario.id)
                                .subscribe((responseProposal: Proposal) => {
                                    responseProposal.scenarios.forEach((scenario: Scenario) => {
                                        scenario?.accounts.forEach((account: Account) => {
                                            if (account?.owners.every((owner: AccountOwner) => owner?.ownerOrder)) {
                                                account.owners.sort((a: AccountOwner, b: AccountOwner) => a.ownerOrder - b.ownerOrder);
                                            }
                                        });
                                    });
                                    this.genericErrorService.clearGenericError();
                                    this.proposalService.changedProposal(responseProposal);
                                    this.delegationService.publish(CommandText.UpdateWipCheckList);
                                    this.proposalService.proposalBackup = undefined;
                                    this.spinnerService.stop();
                                    this.delegationService.publish(CommandText.LoadingStopped);
                                    this.router.navigate([`/Proposal/WIP/${this.proposal.id}`]);
                                }, (error) => {
                                    this.genericErrorService.setGenericError({
                                        code: '',
                                        description: 'Invalid Entry'
                                    });
                                    this.spinnerService.stop();
                                    this.delegationService.publish(CommandText.LoadingStopped);
                                }));
                    }
                    break;
                case CommandText.EnableSaveAndExitButton:
                    this.enableAddAccount = true;
                    break;
                case CommandText.DisableSaveAndExitButton:
                    this.enableAddAccount = false;
                    break;
                case CommandText.CancelRightHandDialog:
                    this.proposal = _.cloneDeep(this.proposalService.proposalBackup);
                    this.proposalService.changedProposal(this.proposal);
                    this.proposalService.proposalBackup = undefined;
                    this.proposal.clients = this.proposal.clients.filter((client: Client) => client.lastName !== undefined);
                    break;
                default:
                    break;
            }

        }));
        this.userProfile = this.userProfileService.getCurrentUserProfile;
        const isInstanceUser: boolean = this.userProfile.entitlements.userLevelId === UserLevelEntitlement.PO ||
            this.userProfile.entitlements.userLevelId === UserLevelEntitlement.Instance;

        if (isInstanceUser) {
            const primaryAdvisorSmuniversalId: number =
                this.proposal.advisors.filter((advisor: Advisor) => advisor.role.id === AdvisorRole.Primary)[0].entityId;

            this.subscriptions.push(this.userProfileRepository.read(primaryAdvisorSmuniversalId).subscribe((userProfile: UserProfile) => {
                this.initializeAccountCard(userProfile);
            }));
        } else {
            this.initializeAccountCard(this.userProfile);
        }
    }

    public onAccountDelete(accountIdToDelete: number): void {
        const scenarioIndex: number =
            this.proposal?.scenarios?.findIndex((scenario: Scenario) => scenario?.id === Number(this.scenario?.id));
        const filteredAccounts: Account [] =
            this.proposal?.scenarios[scenarioIndex]?.accounts?.filter((account) => account?.id !== accountIdToDelete);
        this.proposal.scenarios[scenarioIndex].accounts = filteredAccounts;
        this.proposalService.changedProposal(this.proposal);
        this.validateProposal();
        if (this.scenario?.accounts?.length < 1) {
            this.onAddAccount();
        }
    }

    public onAddAccount(): void {
        this.proposalAccountService.addNewAccountToProposal(this.proposal);
        this.validateProposal();
    }

    public getFilteredAccounts(): Account[] {
        if (this.onlyShowExisting) {
            return this.scenario?.accounts?.filter((account: Account) => account?.currentAccountId);
        } else {
            return this.scenario?.accounts?.filter((account: Account) => !account?.currentAccountId);
        }
    }

    private validateProposal(): void {
        const proposalValid: boolean = this.proposalService.validateProposal(this.proposal, Number(this.scenario.id));
        proposalValid ?
            this.delegationService.publish(CommandText.EnableSaveAndExitButton) :
            this.delegationService.publish(CommandText.DisableSaveAndExitButton);
        this.enableAddAccount = proposalValid;
    }

    private initializeAccountCard(userProfile: UserProfile) {
        this.accountTypesService.getAccountTypes(this.proposal?.advisors[0]?.entityId ||
            this.proposal.scenarios[0]?.accounts[0]?.advisors[0]?.entityId).subscribe((response: AccountType[]) => {
                this.accountTypes = response;
            });

        if (userProfile.firm.processingRules) {
            this.remove403b = !(userProfile.firm.processingRules['Allow403b'] === AllowedFlag);
            this.removeQualifiedPlans = !(userProfile.firm.processingRules['AllowQualifiedPlans'] === AllowedFlag);
        }

        this.appStore.select((slice: IASCommonAppStore): FirmInfoSlice => slice.firmInfoSlice).pipe(
            take(1)
        ).subscribe((slice: FirmInfoSlice) => {
            if (slice?.values?.PlatformFeeBasisPoints) {
                this.platformFeeBasisPoint = Number(slice.values.PlatformFeeBasisPoints);
            }
        });
        if (this.scenario.accounts.length < 1) {
            this.onAddAccount();
        }
        this.validateProposal();
    }
}
