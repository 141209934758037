/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountType } from '@CarInterfaces/account';
import { SeiPayload } from '@CarInterfaces/sei-payload';
import { OwnerRole } from '@CarModels/enums';
import { ExistingAccountData, ExistingAccountModelInvestment, ExistingAccountOwner, ExistingAccountPortfolioPurposeMappingTable, ExistingAccountsRequestBody, ExistingAccountsResponseBody } from '@CarModels/existing-accounts';
import { AccountTypesService } from '@CarServices/account/account-types.service';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { GlobalService } from '@CarServices/system/global.service';
import { Store } from '@ngrx/store';
import { Account, Client, CommandText, CurrentAccountTypeMapping, DelegationService, EntityType, NonModelPortfolioTypeRequest, NonModelPortfolioTypeResponse, OwnerRoleDescription, Proposal, ProposalAccountService, ProposalService, Strategy, Type } from '@sei/advisor-client-review-proposal-ux';
import { FirmInfoSlice, IASCommonAppStore, Portfolio, PortfolioHolding } from '@sei/ias-applications-lib-ux';
import * as _ from 'lodash';
import { AccountTypeOptionSettings } from 'projects/advisor-client-review-proposal-ux/src/lib/model/account-type-options';
import { AccountFactory } from 'projects/advisor-client-review-proposal-ux/src/lib/model/factory/account-factory';
import { FeesFactory } from 'projects/advisor-client-review-proposal-ux/src/lib/model/factory/fees-factory';
import { ContactTypeUtilityService } from 'projects/advisor-client-review-proposal-ux/src/lib/service/contact-type-utility.service';
import { BehaviorSubject, map, Observable, of, take } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ExistingAccountsService {

    private _accountsMap: Map<number, ExistingAccountData[]> = new Map();
    private clientsIdsBeingRetrieved: number[] = [];
    public isExistingAccountCallInProgress: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public existingAccountCallFailed: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public existingAccounts: BehaviorSubject<Map<number, ExistingAccountData[]>> = new BehaviorSubject(this.accountsMap);
    public existingAccountsAsOfDate: BehaviorSubject<string> = new BehaviorSubject(undefined);
    public showNoModelFoundAlert: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private readonly PME_MODEL_TYPE_STRATEGY = 5;
    private readonly PME_MODEL_TYPE_UMA = 7;
    private readonly PROPOSAL_MODEL_TYPE_STRATEGY = 2;
    private readonly PROPOSAL_MODEL_TYPE_UMA = 1;
    private currentAccountToProposedAccountTypeList: string[];
    public existingAccountsToBeAddedToProposal: BehaviorSubject<ExistingAccountData[]> = new BehaviorSubject([]);
    public _existingAccountsToBeAddedToProposalBackUp: ExistingAccountData[] = [];
    private existingAccountModifiedPortfolioPurposeMap: Map<number, Map<number, ExistingAccountPortfolioPurposeMappingTable>> = new Map();

    set accountsMap(value: Map<number, ExistingAccountData[]>) {
        this._accountsMap = value;
    }

    get accountsMap(): Map<number, ExistingAccountData[]> {
        return this._accountsMap;
    }

    constructor(private globalService: GlobalService,
        private httpClient: HttpClient,
        private contactTypeUtilityService: ContactTypeUtilityService,
        private featureFlagService: FeatureFlagService,
        private accountTypesService: AccountTypesService,
        private appStore: Store<IASCommonAppStore>,
        private proposalAccountService: ProposalAccountService,
        private delegationService: DelegationService,
        private proposalService: ProposalService) {
    }

    public retrieveExistingAccountsByIdList(
        clientIds: number[],
        firmId: number,
        primaryAdvisorId: number,
        scenarioId: number,
        proposalId: number,
        forceRefresh: boolean = false,
        proposal: Proposal = undefined): Observable<Map<number, ExistingAccountData[]>> {
        const url: string = this.globalService.configService.routeFormatter(
            this.globalService.configService.getEndPoint('retrieveExistingAccounts')
        );
        const clientIdsNotYetRetrieved: number[] = [];
        clientIds?.forEach((clientId: number): void => {
            const matchingAccounts: ExistingAccountData[] = this.accountsMap.get(Number(clientId));
            if (!matchingAccounts || matchingAccounts.length === 0) {
                clientIdsNotYetRetrieved.push(Number(clientId));
            }
        });
        if ((clientIdsNotYetRetrieved.length > 0 || forceRefresh) && this.featureFlagService.checkExistingAccountsSectionEnabled()) {
            this.isExistingAccountCallInProgress.next(true);
            const clientIdsToRetrieve: number[] = forceRefresh ? clientIds : clientIdsNotYetRetrieved;
            const body: ExistingAccountsRequestBody = this.createExistingAccountRequestBody(clientIdsToRetrieve,
                firmId,
                primaryAdvisorId,
                scenarioId,
                proposalId,
                forceRefresh
            );
            return this.httpClient.post(url, body)
                .pipe(
                    map((response: SeiPayload) => {
                        const existingAccountsResponseBody: ExistingAccountsResponseBody = {
                            accounts: response.data[0].currentAccounts,
                            asOfDate: response.data[0].asOfDate
                        };
                        clientIdsNotYetRetrieved.forEach((clientId: number) => {
                            const accountList: ExistingAccountData[] = existingAccountsResponseBody.accounts
                                .filter((account: ExistingAccountData) =>
                                    account.accountOwnerList.some((owner: ExistingAccountOwner) =>
                                        Number(owner.endClientId) === Number(clientId) && owner.primary)
                                );
                                this.setExistingAccountsEligibilityStatus(accountList);
                            this.accountsMap.set(Number(clientId), accountList);
                            const clientIdIndex: number = this.clientsIdsBeingRetrieved.indexOf(clientId);
                            this.clientsIdsBeingRetrieved.splice(clientIdIndex, 1);
                        });
                        this.clientsIdsBeingRetrieved.length === 0 ?
                            this.isExistingAccountCallInProgress.next(false) :
                            this.isExistingAccountCallInProgress.next(true);
                        this.existingAccountsAsOfDate.next(existingAccountsResponseBody.asOfDate);
                        this.existingAccounts.next(this.accountsMap);
                        this.mapExistingAccountTypeToProposalAccountType();
                        if (forceRefresh && proposal) {
                            this.restoreAccountsFromExistingAccountDataBackUp(proposal);
                            this.delegationService.publish(CommandText.ExistingAccountsRefreshCompleted);
                        }
                        return this.accountsMap;
                    })
                );
        } else {
            this.existingAccounts.next(this.accountsMap);
            if (forceRefresh && proposal) {
                this.restoreAccountsFromExistingAccountDataBackUp(proposal);
                this.delegationService.publish(CommandText.ExistingAccountsRefreshCompleted);
            }
            return of(this.accountsMap);
        }

    }

    public getPortfolioPurposes(request: NonModelPortfolioTypeRequest): Observable<NonModelPortfolioTypeResponse> {
        const url: string = this.globalService.configService.routeFormatter(
            this.globalService.configService.getEndPoint('retrieveNonModelPortfolioTypes'));
        const body: NonModelPortfolioTypeRequest = request;
        return this.httpClient.post<NonModelPortfolioTypeResponse>(url, body);
    }

    public updateModifiedExistingAccountPortfolioMap(value: Map<number, Map<number, ExistingAccountPortfolioPurposeMappingTable>>): void {
        if (value) {
            this.existingAccountModifiedPortfolioPurposeMap = _.cloneDeep(value);
        }
    }

    public retrieveClientIdListFromProposal(proposal: Proposal): number[] {
        const clientIds: number[] = proposal.clients
            .filter((client: Client) => client.entityType === EntityType.Client)
            .map((client: Client) => client.entityId);
        return clientIds;
    }

    public removeUnusedClientAccounts(clients: Client[]): void {
        const clientIdsInMap: number[] = [...this.accountsMap.keys()];
        clientIdsInMap.forEach((clientId: number) => {
            if (!clients.some((client: Client) => Number(client?.entityId) === clientId)) {
                this.accountsMap.delete(clientId);
            }
        });
        this.existingAccounts.next(this.accountsMap);
    }

    public getExistingAccountById(accountId: number): ExistingAccountData {
        const listOfAccountLists: ExistingAccountData[][] = [...this.accountsMap.values()];
        let accountList: ExistingAccountData[] = [];
        listOfAccountLists.forEach((existingAccountList: ExistingAccountData[]) => {
            accountList = accountList.concat(existingAccountList);
        });
        return accountList.find((account: ExistingAccountData) => Number(account.accountId) === Number(accountId));
    }

    private createExistingAccountRequestBody(clientIds: number[],
        firmId: number,
        primaryAdvisorId,
        scenarioId: number,
        proposalId: number,
        forceRefresh: boolean = false
    ):
        ExistingAccountsRequestBody {
        return { swpEndClientIds: clientIds, firmId, primaryAdvisorId, scenarioId, proposalId, forceRefresh };
    }

    public getAccountTypeId(account: ExistingAccountData): number {
        const accountTypeOptionSettings: AccountTypeOptionSettings =
            this.contactTypeUtilityService.getContactTypeFromAccountTypeName(account.accountType);
        return accountTypeOptionSettings?.accountTypeId ?
            accountTypeOptionSettings?.accountTypeId :
            1; // TODO Temporary value. To be fixed when fixing account types without direct mapping to proposal account types
    }

    private getAccountType(accountTypeId: number): AccountType {
        const selectedAccountType: AccountType =
            this.accountTypesService?.accountTypes.find((accountType: AccountType) => accountType?.accountTypeId === accountTypeId);
        return selectedAccountType;
    }


    public createAccountFromCurrentAccount(proposal: Proposal,
        existingAccount: ExistingAccountData,
        accountId: number,
        investmentProgramId: number,
        portfolioMappingsForRevert: Map<number, number> = new Map()): Account {

        const account: Account = new AccountFactory().createObject(accountId);
        account.fees = new FeesFactory().createFees(0, 0, 0, 0, 0, 0, 0, this.getPlatformFeeBasisPoint());
        account.advisors = proposal?.advisors || proposal?.scenarios[0]?.accounts[0]?.advisors;
        account.balance = _.cloneDeep(existingAccount.actualCurrentMarketValue);
        account.currentAccountId = existingAccount.accountId;
        account.currentAccountNumber = existingAccount.accountNumber;
        account.name = existingAccount.accountName;
        const isInvestmentProgramChanged: boolean = investmentProgramId !== existingAccount.investmentProgramId;
        const existingOwner: ExistingAccountOwner =
            existingAccount.accountOwnerList.find((owner: ExistingAccountOwner) => owner.primary);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const client: any =
            proposal.clients.find((proposalClient: Client) => Number(proposalClient.entityId) === Number(existingOwner.endClientId));
        const primaryOwnerRole: Type = { id: OwnerRole.Primary, description: OwnerRoleDescription.Primary };
        client.ownerRole = primaryOwnerRole;
        client.name = client.labelName;
        account.investmentProgramId = existingAccount.investmentProgramId;
        account.owners = [client];
        account.strategies = isInvestmentProgramChanged ? [] : this.mapStrategiesFromExistingAccountData(existingAccount, accountId);
        account.isCurrentAccountInvestmentsModified = false;
        const accountType: AccountType = this.getAccountType(this.getAccountTypeId(existingAccount));
        account.type = {
            id: accountType.accountTypeId,
            accountTypeId: accountType.accountTypeId,
            description: accountType.accountTypeDescription,
            accountTypeDescription: accountType.accountTypeDescription,
            groupType: accountType.groupType,
            sortOrder: accountType.sortOrder,
            taxQualified: accountType.taxQualified,
            qualifiedPlan: accountType.qualifiedPlan,
            allowJointOwners: accountType.allowJointOwners,
            nonTaxableStatus: existingAccount.nonTaxableStatus
        };
        account.isNewAccount = true;
        account.sweepModelElection = {
            isFdic: existingAccount.fdic,
            currencySweepModelAllocDescription: existingAccount.sweepModelDescription,
            platformSweepModelId: existingAccount.sweepModelId
        };
        account.platformFeeCapDetails = existingAccount.platformFeeCapDetails;
        this.assignTemporaryIdToPortfolioItems(existingAccount.proposalTrackedPortfolios);
        if (this.existingAccountModifiedPortfolioPurposeMap.has(existingAccount.accountId) && portfolioMappingsForRevert.size === 0) {
            const portfolios: Portfolio[] = this.mapPortfolioPurposeChanges(existingAccount, account);
            const filteredPortfolios: Portfolio[] = this.filterOutPortfoliosWithNoMarketValue(portfolios);
            account.portfolios = _.cloneDeep(filteredPortfolios);
            account.existingPortfolios = _.cloneDeep(filteredPortfolios);
        } else {
            const filteredPortfolios: Portfolio[] = this.filterOutPortfoliosWithNoMarketValue(existingAccount?.existingNonModelPortfolios);
            this.reapplyPortfolioMappingsForRevert(filteredPortfolios, portfolioMappingsForRevert);
            account.portfolios = _.cloneDeep(filteredPortfolios);
            account.existingPortfolios = _.cloneDeep(filteredPortfolios);
        }
        return account;
    }

    private filterOutPortfoliosWithNoMarketValue(portfolios: Portfolio[]): Portfolio[] {
        let filteredPortfolios: Portfolio[] = [];
        if (portfolios && portfolios.length) {
            filteredPortfolios = portfolios
                .filter((portfolioItem: Portfolio) => this.proposalAccountService.calculateValueOfNonModelPortfolio(portfolioItem) !== 0);
        }
        return filteredPortfolios;
    }

    private reapplyPortfolioMappingsForRevert(portfolios: Portfolio[], mappings: Map<number, number>): void {
        if (portfolios && portfolios.length && mappings?.size > 0) {
            portfolios.forEach((portfolio) => {
                if (mappings?.has(portfolio?.existingAccountPortfolioId)) {
                    portfolio.portfolioPurpose.portfolioPurposeId = mappings?.get(portfolio?.existingAccountPortfolioId);
                }
            });
        }
    }

    private mapPortfolioPurposeChanges(existingAccount: ExistingAccountData, account: Account): Portfolio[] {
        const changedPortfolioMap: Map<number, ExistingAccountPortfolioPurposeMappingTable> =
            this.existingAccountModifiedPortfolioPurposeMap.get(existingAccount.accountId);
        const mappedPortfolios: Portfolio[] = existingAccount.existingNonModelPortfolios;
        const finalPortfolios: Portfolio[] = [];
        mappedPortfolios.forEach((portfolio: Portfolio) => {
            if (changedPortfolioMap.has(portfolio.existingAccountPortfolioId)) {
                const changedPortfolio = changedPortfolioMap.get(portfolio.existingAccountPortfolioId);
                if (!changedPortfolio.isDeleted) {
                    portfolio.portfolioPurpose = changedPortfolio.selectedPortfolioPurpose;
                    finalPortfolios.push(portfolio);
                } else {
                    const portfolioTotal: number = this.proposalAccountService.calculateValueOfNonModelPortfolio(portfolio);
                    if (portfolioTotal > 0) {
                        account.isCurrentAccountInvestmentsModified = true;
                    }
                }
            } else {
                finalPortfolios.push(portfolio);
            }
        });
        return finalPortfolios;
    }

    private mapStrategiesFromExistingAccountData(existingAccount: ExistingAccountData, accountId): Strategy[] {
        const strategies: Strategy[] = [];
        existingAccount.modelInvestments.forEach((modelInvestment: ExistingAccountModelInvestment) => {
            const strategy: Strategy = {
                proposalAccountStrategyId: 0,
                proposalAccountId: accountId,
                swpStrategyId: undefined,
                cusipId: undefined,
                amount: modelInvestment.amount,
                proposalScenarioGoalId: undefined,
                strategyTypeId: undefined,
                strategyTypeName: undefined,
                fundTickerId: undefined,
                modelId: modelInvestment.modelId,
                customModelTypeId: undefined,
                customModelTypeName: undefined,
                customModelName: undefined,
                modelTypeId: modelInvestment.modelType === this.PME_MODEL_TYPE_STRATEGY ?
                    this.PROPOSAL_MODEL_TYPE_STRATEGY : this.PROPOSAL_MODEL_TYPE_UMA,
                modelTypeName: undefined,
                startingNode: undefined,
                nodeLevel: undefined,
                customStrategyId: undefined,
                customStrategy: undefined,
                investmentProgramId: undefined,
                investmentProgramName: undefined,
                strategyName: modelInvestment.modelName
            };
            strategies.push(strategy);
        });
        return strategies;
    }
    public assignTemporaryIdToPortfolioItems(portfolios: Portfolio[]): void {
        let id: number = 1;
        portfolios.forEach((portfolio: Portfolio) => {
            portfolio?.proposedPortfolioHoldingList?.forEach((holding: PortfolioHolding) => {
                if (!holding?.proposalPortfolioHoldingId) {
                    holding.proposalPortfolioHoldingId = id;
                    id++;
                }
            });
        });
    }

    public resetTemporaryPortfolioIds(account: Account): void {
        if (account) {
            account?.portfolios?.forEach((portfolio: Portfolio) => {
                portfolio.proposalPortfolioId = null;
                portfolio?.proposedPortfolioHoldingList?.forEach((holdingData: PortfolioHolding) => {
                    holdingData.proposalPortfolioHoldingId = null;
                });
            });
        }
    }

    public updateExistingAccount(updatedExistingAccount: Account): void {
        if (updatedExistingAccount) {
            const clonedExistingAccountData: Map<number, ExistingAccountData[]> = _.cloneDeep(this.existingAccounts?.value);
            const availableClientIds: number[] = Array.from(clonedExistingAccountData?.keys());
            availableClientIds.forEach((clientId: number) => {
                const clientExistingAccounts: ExistingAccountData[] = clonedExistingAccountData?.get(clientId);
                if (clientExistingAccounts) {
                    const accountToUpdate: ExistingAccountData =
                        clientExistingAccounts?.find((account) => Number(account?.accountId) ===
                            Number(updatedExistingAccount?.currentAccountId));
                    if (accountToUpdate) {
                        accountToUpdate.proposalTrackedPortfolios = updatedExistingAccount?.portfolios;
                    }
                }
            });
            this._accountsMap = clonedExistingAccountData;
            this.existingAccounts.next(clonedExistingAccountData);
        }
    }

    public updateExistingAccountMap(existingAccountMap: Map<number, ExistingAccountData[]>): void {
        if (existingAccountMap) {
            this._accountsMap = _.cloneDeep(existingAccountMap);
        }
    }

    public filterDeletedPortfoliosAndHoldings(portfoliosToFilter: Portfolio[]): Portfolio[] {
        const filteredPortfolios =
            portfoliosToFilter.filter((portfolio: Portfolio) => portfolio.isExistingAccountPortfolioDeleted === false);
        filteredPortfolios.forEach((portfolio) => {
            const filteredHoldings =
                portfolio.proposedPortfolioHoldingList.filter((holding) => holding.isExistingAccountHoldingDeleted === false);
            portfolio.proposedPortfolioHoldingList = filteredHoldings;
        });
        return filteredPortfolios;
    }

    public resetExistingAccounts(): void {
        this.existingAccountsAsOfDate.next(undefined);
    }

    private getPlatformFeeBasisPoint(): number {
        let platformFeeBasisPoint: number = 0;
        this.appStore.select((slice: IASCommonAppStore): FirmInfoSlice => slice.firmInfoSlice).pipe(take(1))
            .subscribe((slice: FirmInfoSlice) => {
                if (slice?.values?.PlatformFeeBasisPoints) {
                    platformFeeBasisPoint = Number(slice.values.PlatformFeeBasisPoints);
                }
            });
        return platformFeeBasisPoint;
    }

    private mapExistingAccountTypeToProposalAccountType(): void {
        if (this._accountsMap) {
            this.currentAccountToProposedAccountTypeList = Object.keys(CurrentAccountTypeMapping);
            this._accountsMap.forEach((value: ExistingAccountData[], key: number) => {
                if (value) {
                    value.forEach((account) => {
                        if (this.currentAccountToProposedAccountTypeList.includes(account.accountType)) {
                            let selectedAccountType: AccountType;
                            const enumItem: string[] = CurrentAccountTypeMapping[account.accountType].split(',');
                            if (enumItem.length === 1) {
                                selectedAccountType = this.accountTypesService.accountTypes.find((accountType: AccountType) =>
                                    accountType.accountTypeId.toString() === enumItem[0]);
                                account.accountType = selectedAccountType.accountTypeDescription;
                            }
                        }
                    });
                }
            });
        }
    }

    public getClientByEntityId(clients: Client[], entityId: number): Client {
        let client: Client;
        if (clients?.length && entityId) {
            const matchingClient: Client = clients.find((c: Client) => Number(c?.entityId) === entityId);
            if (matchingClient) {
                client = matchingClient;
            }
        }
        return client;
    }

    public removeExistingAccountFromPickList(existingAccount: ExistingAccountData): void {
        if (existingAccount && existingAccount?.accountId) {
            const currentPickList: ExistingAccountData[] = this.existingAccountsToBeAddedToProposal?.value;
            const matchingAccount: ExistingAccountData =
                currentPickList.find((account: ExistingAccountData) => account?.accountId === existingAccount?.accountId);
            if (matchingAccount) {
                const filteredAccounts: ExistingAccountData[] =
                    currentPickList.filter((account: ExistingAccountData) => account?.accountId !== existingAccount?.accountId);
                this.existingAccountsToBeAddedToProposal.next(filteredAccounts);
            }
        }
    }

    public addExistingAccountFromPickList(existingAccount: ExistingAccountData): void {
        if (existingAccount && existingAccount?.accountId) {
            const currentPickList: ExistingAccountData[] = this.existingAccountsToBeAddedToProposal.value;
            currentPickList.push(existingAccount);
            this.existingAccountsToBeAddedToProposal.next(currentPickList);
        }
    }

    public createBackUpOfSelections(): void {
        this._existingAccountsToBeAddedToProposalBackUp = _.cloneDeep(this.existingAccountsToBeAddedToProposal.value);
    }

    public restoreSelectionsFromBackUp(): void {
        this.existingAccountsToBeAddedToProposal.next(_.cloneDeep(this._existingAccountsToBeAddedToProposalBackUp));
    }

    public isExistingAccountSelectedToBeAddedToProposal(existingAccount: ExistingAccountData): boolean {
        if (existingAccount) {
            const currentPickList: ExistingAccountData[] = this.existingAccountsToBeAddedToProposal?.value;
            const isSelected: ExistingAccountData =
                currentPickList.find((account: ExistingAccountData) => account?.accountId === existingAccount?.accountId);
            if (isSelected) {
                return true;
            }
        }
        return false;
    }

    public removeExistingAccountsNotInProposal(proposal: Proposal) {
        const existingAccounts: ExistingAccountData[] = this.existingAccountsToBeAddedToProposal?.getValue()
            ?.filter((existingAccount: ExistingAccountData) =>
                proposal.scenarios[0].accounts.some((account: Account) => account.currentAccountId === existingAccount.accountId));
        this.existingAccountsToBeAddedToProposal.next(existingAccounts);
        this._existingAccountsToBeAddedToProposalBackUp = _.cloneDeep(existingAccounts);
    }

    public addExistingAccountsFromProposal(proposal: Proposal) {
        const existingAccounts: ExistingAccountData[] = this.existingAccountsToBeAddedToProposal?.getValue();
        proposal?.scenarios[0]?.accounts?.forEach((account: Account) => {
            if (!existingAccounts?.some((existingAccount: ExistingAccountData) =>
                account?.currentAccountId === existingAccount?.accountId)) {
                const allExistingAccounts: ExistingAccountData[] = this.convertExistingAccountsMapToArray(this._accountsMap);
                const accountToBeSelected: ExistingAccountData = allExistingAccounts.find((accountData: ExistingAccountData) =>
                    accountData?.accountId === account?.currentAccountId);
                existingAccounts.push(accountToBeSelected);
            }
        });
        this.existingAccountsToBeAddedToProposal.next(existingAccounts);
        this._existingAccountsToBeAddedToProposalBackUp = _.cloneDeep(existingAccounts);
    }

    public convertExistingAccountsMapToArray(accountsMap: Map<number, ExistingAccountData[]>): ExistingAccountData[] {
        let accountList: ExistingAccountData[] = [];
        const listOfAccountLists: ExistingAccountData[][] = Array.from(accountsMap.values());
        // Add each list of accounts to the account list
        listOfAccountLists.forEach((list: ExistingAccountData[]) => {
            accountList = accountList.concat(list);
        });
        return accountList;
    }

    public deselectAllExistingAccounts(): void {
        this.existingAccountsToBeAddedToProposal.next([]);
    }

    public isPrimaryAdvisorInfoPresentInSF(existingAccount: ExistingAccountData): boolean {
        return existingAccount?.currentAccountPrimaryAdvisorInfo === undefined ? false :
            !existingAccount?.currentAccountPrimaryAdvisorInfo.isAdvisorPresentInSF;
    }

    public selectAllEligibleExistingAccounts(): void {
        const allExistingAccounts: ExistingAccountData[] = this.convertExistingAccountsMapToArray(this.accountsMap);
        const filteredExistingAccounts: ExistingAccountData[] =
            allExistingAccounts.filter(this.checkIfAccountIsEligibleForSelection.bind(this));
        this.existingAccountsToBeAddedToProposal.next(filteredExistingAccounts);
    }

    private checkIfAccountIsEligibleForSelection(existingAccount: ExistingAccountData): boolean {
        const doesAccountHaveMarketValue: boolean = existingAccount?.modelMarketValue > 0 ||
            existingAccount?.nonModelMarketValue > 0;
        return doesAccountHaveMarketValue && !this.isPrimaryAdvisorInfoPresentInSF(existingAccount)
            && existingAccount.isAccountEligibleForSelection;
    }

    public retainPortfolioMappingsForRevert(existingAccount: ExistingAccountData): Map<number, number> {
        const portfolioMappings: Map<number, number> = new Map();
        if (existingAccount && existingAccount.existingNonModelPortfolios?.length) {
            existingAccount.existingNonModelPortfolios.forEach((portfolio) => {
                portfolioMappings.set(portfolio?.existingAccountPortfolioId, portfolio?.portfolioPurpose?.portfolioPurposeId);
            });
        }
        return portfolioMappings;
    }

    public removeExistingAccountsFromMapByClientId(clientId: number): ExistingAccountData[] {
        const currentAccountsMap: Map<number, ExistingAccountData[]> = _.cloneDeep(this.accountsMap);
        const accountsBackup: ExistingAccountData[] = this.accountsMap.get(clientId);
        currentAccountsMap.delete(clientId);
        this.existingAccounts.next(currentAccountsMap);
        return accountsBackup;
    }

    public restoreExistingAccountsForClients(backupMap: Map<number, ExistingAccountData[]>): void {
        this.existingAccounts.next(new Map([...this.accountsMap].concat([...backupMap])));
    }

    public restoreAccountsFromExistingAccountDataBackUp(proposal: Proposal): void {
        this.createBackUpOfSelections();
        const reconstructedAccounts: Account[] = [];
        const onlyProposedAccounts: Account[] = proposal?.scenarios[0]?.accounts?.filter((account) => !account?.currentAccountNumber);
        this._existingAccountsToBeAddedToProposalBackUp?.forEach((existingAccountData: ExistingAccountData, i: number) => {
            reconstructedAccounts.push(
                this.createAccountFromCurrentAccount(proposal, existingAccountData, i, existingAccountData?.investmentProgramId)
            );
        });
        proposal.scenarios[0].accounts = _.cloneDeep(onlyProposedAccounts.concat(reconstructedAccounts));
        this.proposalService.changedProposal(proposal);
    }

    public clearClientsMapForSnapshotGeneration(): void {
        this.accountsMap = new Map();
    }

    private setExistingAccountsEligibilityStatus(existingAccounts: ExistingAccountData[]): void {
        if (existingAccounts && existingAccounts.length) {
            existingAccounts.forEach((existingAccount) => {
                existingAccount.isAccountEligibleForSelection =
                    existingAccount?.modelInvestments
                        ?.every((model) => model?.isModelAvailableInModelDetail || model?.isModelAvailableInModelDetail === null);
            });
        }
    }
}
