/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { ExistingAccountData } from '@CarModels/existing-accounts';
import { ExistingAccountsService } from '@CarServices/existing-accounts/existing-accounts.service';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { SpinnerService } from '@CarServices/system/spinner.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Account, AccountOwner, Client, CommandText, DelegationService, EntityType, GenericErrorService, Proposal, ProposalAccountService, ProposalService, RightHandFooterFunctions, Scenario, WipCheckList } from '@sei/advisor-client-review-proposal-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Portfolio, PortfolioHolding } from '@sei/ias-applications-lib-ux';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sei-car-proposal-all-accounts',
    templateUrl: './all-accounts.component.html',
    styleUrls: ['./all-accounts.component.scss']
})
export class AllAccountsComponent extends ParentComponentSubscriptionManager implements OnInit {

    public proposal: Proposal;
    public proposalBackup: Proposal;
    public scenario: Scenario;
    public existingAccountsAddedToProposal: Account[] = [];
    public existingAccounts: Map<number, ExistingAccountData[]>;
    public isAddProposedAccountsButtonDisabled: boolean = false;
    public selectedExistingAccounts: ExistingAccountData[] = [];
    public footerFunction: string = RightHandFooterFunctions.EditProposedAccounts;
    public isExistingAccountsFeatureEnabled: boolean = false;
    public getHoldingsCallFailed: boolean = false;
    constructor(private router: Router,
        private existingAccountsService: ExistingAccountsService,
        private genericErrorService: GenericErrorService,
        private proposalService: ProposalService,
        private delegationService: DelegationService,
        private proposalAccountService: ProposalAccountService,
        private featureFlagService: FeatureFlagService,
        private spinnerService: SpinnerService) {
        super('AllAccountsComponent');
    }

    ngOnInit(): void {
        this.isExistingAccountsFeatureEnabled = this.featureFlagService.checkExistingAccountsSectionEnabled();
        const proposalSubscription: Subscription = this.proposalService.currentProposal.subscribe({
            next: (proposal: Proposal): void => {
                if (proposal) {
                    this.proposal = proposal;
                    this.scenario = proposal?.scenarios[0];
                    const isProposalValid: boolean = this.proposalService.validateProposal(this.proposal, this.proposal.scenarios[0].id);
                    isProposalValid ? this.delegationService.publish(CommandText.EnableSaveAndExitButton) :
                        this.delegationService.publish(CommandText.DisableSaveAndExitButton);
                    if (!this.featureFlagService.checkExistingAccountsSectionEnabled() && !this.proposal.scenarios[0]?.accounts.length) {
                        this.proposalAccountService.addNewAccountToProposal(this.proposal);
                    }
                }
            },
            error: (error) => {
                this.genericErrorService.setGenericError({ code: '', description: `${error.message}` });
            }
        });
        this.subscriptions.push(proposalSubscription);
        if (this.proposal) {
            this.proposalBackup = _.cloneDeep(this.proposal);
        }

        const existingAccountsSubscription: Subscription =
            this.existingAccountsService.existingAccounts.subscribe({
                next: (existingAccounts: Map<number, ExistingAccountData[]>): void => {
                    if (existingAccounts.size > 0) {
                        this.existingAccounts = existingAccounts;
                        setTimeout(() => {
                            this.setExistingAccountsFromProposal();
                            this.existingAccountsService.createBackUpOfSelections();
                        });
                    }
                },
                error: (error) => {
                    this.genericErrorService.setGenericError({ code: '', description: `${error.message}` });
                }
            });
        this.subscriptions.push(existingAccountsSubscription);

        const selectedExistingAccountsSubscription: Subscription =
            this.existingAccountsService.existingAccountsToBeAddedToProposal
                .subscribe({
                    next: (selectedExistingAccounts: ExistingAccountData[]) => {
                        this.selectedExistingAccounts = selectedExistingAccounts;
                        const convertedAccounts: Account[] = this.selectedExistingAccounts
                            .filter((accountData: ExistingAccountData) =>
                                !this.proposal.scenarios[0].accounts.some((account: Account) =>
                                    account.currentAccountId === accountData.accountId))
                            .map((existingAccountData: ExistingAccountData, index: number) => {
                                const addedAccountIndex: number = this.proposal.scenarios[0].accounts.length + index;
                                return this.existingAccountsService.createAccountFromCurrentAccount(this.proposal,
                                    existingAccountData,
                                    addedAccountIndex,
                                    existingAccountData?.investmentProgramId);
                            });
                        this.proposal.scenarios[0].accounts.forEach((account: Account, index: number) => {
                            if (account.currentAccountId && !this.selectedExistingAccounts
                                .some((selectedAccount: ExistingAccountData) => selectedAccount.accountId === account.currentAccountId)) {
                                this.proposal.scenarios[0].accounts.splice(index, 1);
                            }
                        });
                        this.proposal.scenarios[0].accounts = [...this.proposal.scenarios[0].accounts, ...convertedAccounts];
                    },
                    error: (error) => {
                        this.genericErrorService.setGenericError({ code: '', description: `${error.message}` });
                    }
                });
        this.subscriptions.push(selectedExistingAccountsSubscription);

        const delegationSubscription: Subscription = this.delegationService.refresh().subscribe({
            next: (command: string): void => {
                switch (command) {
                    case CommandText.EnableSaveAndExitButton:
                        this.isAddProposedAccountsButtonDisabled = false;
                        break;
                    case CommandText.DisableSaveAndExitButton:
                        this.isAddProposedAccountsButtonDisabled = true;
                        break;
                    case CommandText.SaveEditProposal:
                        if (this.isExistingAccountsFeatureEnabled) {
                            this.proposalService.fixProposalForSave(this.proposal);
                            this.proposal.scenarios[0].accounts = this.proposal.scenarios[0].accounts
                                .filter((account: Account) =>
                                    !account.currentAccountId ||
                                    this.selectedExistingAccounts?.some((existingAccount: ExistingAccountData) =>
                                        existingAccount.accountId === account.currentAccountId));
                            this.proposal.scenarios[0].accounts.forEach((account: Account) => {
                                account?.portfolios?.forEach((portfolio: Portfolio) => {
                                    portfolio.proposalPortfolioId = undefined;
                                    portfolio.proposedPortfolioHoldingList.forEach((holding: PortfolioHolding) => {
                                        holding.proposalPortfolioHoldingId = undefined;
                                    });
                                });
                            });
                            this.spinnerService.start();
                            this.subscriptions.push(
                                this.proposalService.updateProposalScenario(this.proposal, this.scenario.id)
                                    .subscribe((responseProposal: Proposal) => {
                                        responseProposal.scenarios.forEach((scenario: Scenario) => {
                                            scenario?.accounts.forEach((account: Account) => {
                                                if (account?.owners.every((owner: AccountOwner) => owner?.ownerOrder)) {
                                                    account.owners.sort((a: AccountOwner, b: AccountOwner) => a.ownerOrder - b.ownerOrder);
                                                }
                                            });
                                        });
                                        this.genericErrorService.clearGenericError();
                                        this.proposalService.changedProposal(responseProposal);
                                        this.proposalAccountService.updateWipChecklistsForCurrentAccounts(responseProposal);
                                        this.subscriptions.push(
                                            this.proposalService.updateProposalWipCheckList(responseProposal.wipCheckList)
                                                .subscribe((updatedWipChecklist: WipCheckList[]) => {
                                                    responseProposal.wipCheckList = updatedWipChecklist;
                                                    this.delegationService.publish(CommandText.UpdateWipCheckList);
                                                    this.proposalService.proposalBackup = undefined;
                                                    this.spinnerService.stop();
                                                    this.delegationService.publish(CommandText.LoadingStopped);
                                                    this.router.navigate([`/Proposal/WIP/${this.proposal.id}`]);
                                                }, (error) => {
                                                    this.genericErrorService.setGenericError({
                                                        code: '',
                                                        description: 'Invalid Entry'
                                                    });
                                                    this.spinnerService.stop();
                                                    this.delegationService.publish(CommandText.LoadingStopped);
                                                }));
                                    }, (error) => {
                                        this.genericErrorService.setGenericError({
                                            code: '',
                                            description: 'Invalid Entry'
                                        });
                                        this.spinnerService.stop();
                                        this.delegationService.publish(CommandText.LoadingStopped);
                                    }));
                        }
                        break;
                    case CommandText.AccountsFlyoutCancel:
                        this.existingAccountsService.restoreSelectionsFromBackUp();
                        this.proposal = _.cloneDeep(this.proposalBackup);
                        this.proposalService.changedProposal(this.proposal);
                        this.delegationService.publish(CommandText.UpdateWipCheckList);
                        this.router.navigate([`/Proposal/WIP/${this.proposal.id}`]);
                        break;
                    default:
                        break;
                }
            },
            error: (error) => {
                this.genericErrorService.setGenericError({ code: '', description: `${error.message}` });
            }
        });
        this.subscriptions.push(delegationSubscription);

        const getHoldingsFailureStatus: Subscription =
            this.existingAccountsService.existingAccountCallFailed.subscribe((getHoldingsStatus: boolean): void => {
                this.getHoldingsCallFailed = getHoldingsStatus;
            });
        this.subscriptions.push(getHoldingsFailureStatus);
    }

    public selectExistingAccountsClicked(): void {
        this.router.navigate([`/Proposal/WIP/${this.proposal?.id}/Scenarios/${this.scenario.id}/ExistingAccounts`]);
    }

    public addProposedAccountsClicked(): void {
        this.proposalAccountService.addNewAccountToProposal(this.proposal);
    }

    public isProposedAccountsSectionVisible(): boolean {
        return this.proposal?.scenarios[0]?.accounts
            ?.filter((account: Account) => !account.currentAccountId).length > 0;
    }

    public isProposedExistingAccountsSectionVisible(): boolean {
        return this.selectedExistingAccounts.length > 0;
    }

    public isMaximumNumberOfAccountsReached(): boolean {
        return this.proposalAccountService
            .checkIfMaximumAccountsHasBeenReached(this.selectedExistingAccounts, this.proposal?.scenarios[0]?.accounts, true);
    }

    public showSelectExistingAccountsButton(): boolean {
        return this.isExistingAccountsFeatureEnabled &&
            this.proposal.clients.some((client: Client) => client.entityType === EntityType.Client);
    }

    private setExistingAccountsFromProposal(): void {
        this.scenario.accounts.forEach((account: Account) => {
            if (account.currentAccountId &&
                !this.selectedExistingAccounts.some((existingAccount: ExistingAccountData) =>
                    Number(existingAccount.accountId) === Number(account.currentAccountId))) {
                const clientExistingAccountList: ExistingAccountData[] = this.existingAccounts.get(Number(account.owners[0].entityId));
                const currentAccountSnapshot = clientExistingAccountList?.find((existingAccount: ExistingAccountData) =>
                    Number(account.currentAccountId) === Number(existingAccount.accountId));
                this.selectedExistingAccounts.push(currentAccountSnapshot);
            }
        });
    }

}
