/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WizardModel, WizardState } from '@sei/common-components-lib-ux';
import { AbstractValueAccessor, MakeValueAccessorProvider } from '@sei/common-swp-components-lib-ux';

@Component({
    selector: 'sei-car-toggle-sei-wizard-nav',
    templateUrl: './toggle-sei-wizard-nav.component.html',
    providers: [MakeValueAccessorProvider(ToggleSeiWizardNavComponent)]
})

export class ToggleSeiWizardNavComponent extends AbstractValueAccessor<boolean> implements OnInit {
    @Input('value')
    public toggleValue: boolean;

    @Input('minWidth')
    minWidth: number = 15;

    @Input('isCustomModel')
    public isCustomModel: boolean = false;

    @Input('isDisabled')
    public isDisabled: boolean = false;

    @Input('wizardSteps')
    set wizardSteps(value: WizardModel[]) {
        this._wizardSteps = value;
    }

    get wizardSteps(): WizardModel[] {
        return this._wizardSteps;
    }

    @Input()
    public isReadUser?: boolean;

    @Input()
    public set isReadOnly(value: boolean) {
        this._isReadOnly = value;
    }

    public get isReadOnly(): boolean {
        return this._isReadOnly;
    }

    @Output()
    public onChangeEvent = new EventEmitter();

    public onChange: any = () => { };
    public _isReadOnly: boolean = false;
    private _wizardSteps: WizardModel[] = [
        { name: 'Yes', state: WizardState.ACTIVE },
        { name: 'No', state: WizardState.ENABLE }
    ];

    get value(): boolean {
        if (!this.isCustomModel) {
            this.setFirstItem();
        }

        return this.toggleValue;
    }

    set value(value) {
        this.toggleValue = value;
        this.setFirstItem();

        this.onChange(value);
    }

    public ngOnInit(): void {
        if (!this.isCustomModel) {
            this.setFirstItem();
        }
    }

    private setFirstItem(): void {

        if (this.isReadUser) {
            this.wizardSteps[0].state = WizardState.DISABLE;
            this.wizardSteps[1].state = WizardState.DISABLE;
            return;
        }

        if (this.isDisabled) {
            this.wizardSteps[0].state = WizardState.DISABLE;
            this.wizardSteps[1].state = WizardState.ACTIVE;
            return;
        }

        if (this.toggleValue) {
            // Note: We will set 1st item as active
            this.wizardSteps[0].state = WizardState.ACTIVE;
            this.wizardSteps[1].state = this.isReadOnly ? WizardState.DISABLE : WizardState.ENABLE;
        } else {
            this.wizardSteps[0].state = WizardState.ENABLE;
            this.wizardSteps[1].state = WizardState.ACTIVE;
        }
    }

    private removeActive() {
        this.wizardSteps.forEach((step) => step.state = WizardState.ENABLE);
    }

    public writeValue(value: boolean): void {
        if (value) {
            this.value = value;
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        // this.onTouched = fn;
    }

    public onWizardStepSelect(selectedItem: WizardModel): void {
        if (!this.isCustomModel) {
            if (
                selectedItem.state !== WizardState.DISABLE &&
                selectedItem.state !== WizardState.HIDDEN
            ) {
                this.value = (selectedItem.name === 'Yes');
            }
        } else {
            this.value = (selectedItem.name === 'Include');
        }
        this.removeActive();
        selectedItem.state = WizardState.ACTIVE;
        this.onChangeEvent.emit(this.value);
    }
}
